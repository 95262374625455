import React from "react";
import Tags from "./Tags";
import icons from "../utils/icon";
import { Link } from "react-router-dom";
import timeSince from "../utils/helper/timeago";
import { generateSlug } from "../utils/helper/banner";
import sortByEaryFirst from "../utils/helper/sortByYearFirst";

const JobCard = ({ jobs }) => {

  let {
    datePosted,
    description,
    jobType,
    image,
    jobLink,
    role,
    location,
    jobTitle,
    jobID,
    companyName,
    tags: { batch },
  } = jobs;

  const slug = generateSlug(jobTitle);

  batch = sortByEaryFirst(batch);

  //to Show the tags in the order of numbers first and then alphabets
  //   batch.sort((a, b) => {
  //     const numA = parseInt(a);
  //     const numB = parseInt(b);

  //     if (!isNaN(numA) && !isNaN(numB)) return numA - numB;

  //     if (!isNaN(numA)) return -1;
  //     if (!isNaN(numB)) return 1;

  //     return a.localeCompare(b);
  // });

  return (
    <div className="main-body flex flex-col gap-3 p-4 rounded-md w-96 hover:scale-105 sm:hover:scale-100 animate__animated animate__zoomIn">
      {/* top */}
      <div className="flex justify-between">
        <div className="flex gap-2 items-center">
          <img
            className="w-10 rounded-[50px] sm:w-8"
            src={image || ""}
            alt="company-logo"
          />
          <div>
            <h1 className="text-[1.1rem] mb-0 sm:text-[1rem]">
              {companyName?.slice(0, 23) || "N/A"}
            </h1>
            <p className="mb-0 text-[0.8rem] text-gray-400 sm:text-[0.8rem]">
              {jobType?.slice(0, 23) || "N/A"}
            </p>
          </div>
        </div>
        <p className="text-[0.8rem] text-gray-400 sm:text-[0.8rem]">
          {timeSince(datePosted || new Date())}
        </p>
      </div>
      {/* middle */}
      <div className="flex flex-1 flex-col ">
        <h1 className="mb-0 text-[1.1rem] sm:text-[1rem]">
          {jobTitle?.slice(0, 33) || "N/A"}
        </h1>
        <p className="mb-3 text-[0.8rem] text-gray-400 font-semibold sm:text-[0.8rem]">
          {description?.slice(0, 66) || "N/A"}
        </p>
        <div>
          <div className="flex flex-wrap gap-2 items-center mb-2">
            {batch?.map((item, index) => <Tags key={index} batch={item} />) ||
              null}
            {location && <Tags batch={location} />}
          </div>
        </div>
      </div>

      {/* bottom */}
      <div className="flex justify-between items-center">
        <Link to={{ pathname: `/jobdetails/${jobID}/${slug}` }}>
          <button className="text-[0.9rem] bg-blue-500 rounded-md text-white font-semibold px-8 py-2  sm:text-[0.8rem] hover:bg-blue-700 ">
            Details
          </button>
        </Link>
        <Link
          to={{ pathname: `/discuss/${jobID}/${slug}` }}
          className="no-underline"
        >
          <button className="flex items-center gap-2 text-[0.9rem] bg-gray-200 rounded-md py-2 px-4 text-gray-500 font-semibold hover:bg-gray-400 sm:text-[0.8rem] hover:text-white">
            <span className="font-bold text-lg">{icons.discuss}</span> Discuss
          </button>
        </Link>
      </div>
    </div>
  );
};

export default JobCard;
