import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Icons from "../utils/icon";
import axios from "axios";
import toast from "react-hot-toast";
import Tags from "../components/Tags";
import sortByEaryFirst from "../utils/helper/sortByYearFirst";
import timeSince from "../utils/helper/timeago";
import {bannerImageArray, generateSlug} from '../utils/helper/banner'
import Loader from '../components/Loader'
import Notfound from '../Pages/Notfound'
import ReactGA from 'react-ga4';

const JobDescription = ({isLoggedIn}) => {
  const {jobId,slug} = useParams();
  const [isJobSaved, setIsJobSaved] = useState(false);
  const [randomBanner, setRandomBanner] = useState("");
  const [jobData, setJobData] = useState({});
  const [validurl,setvalidurl]= useState(true);

  useEffect(() => {
    const fetchJob = async () => {
      try {
        if(isNaN(jobId)){
          setvalidurl(false);
          return;
        }
        const res = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/api/jobs/getjob/${jobId}`
        );
        setJobData(res.data);
        if(slug!==generateSlug(res.data.jobTitle)) setvalidurl(false);
      } catch (e) {
        if(e.response && e.response.data){
          // toast.error(e.response.data.message);
           setvalidurl(false);
        }
      }
    };

    // useEffect(() => {
    //   ReactGA.send({ hitType: "pageview", page: window.location.pathname });
    // }, []);

    const checkIfJobSaved = () => {
      const existingJobs = JSON.parse(localStorage.getItem('savedJobs'));
      if(existingJobs!==null){
         const JobFound= existingJobs.filter(item=> String(item.jobID)===jobId);
         if(JobFound.length!==0) setIsJobSaved(true);
      }
    };

    const randomImage = bannerImageArray[Math.floor(Math.random() * bannerImageArray.length)];
    setRandomBanner(randomImage);
    fetchJob();
    checkIfJobSaved();
  }, [jobId]); 

  let {
    datePosted,
    description,
    jobType,
    image,
    jobLink,
    jobTitle,
    companyName,
    tags,
  } = jobData || {};
  let batch = tags?.batch;
  batch=batch && sortByEaryFirst(batch);

  const addToLocalStorage=()=>{
    if(!isLoggedIn){
      toast.error("login to save this Job");
      return;
    }
     const existingJobs = JSON.parse(localStorage.getItem('savedJobs')) || [];
     existingJobs.push(jobData);
     localStorage.setItem('savedJobs', JSON.stringify(existingJobs));
     setIsJobSaved(true);
     toast.success("Job Saved");
  }

  
  const formatText = (text) => {
    const lines = text.split('\n');
    let inList = false;
    let listItems = [];
    const elements = [];
  
    lines.forEach((line, index) => {
      line = line.trim();
  
      // If the line starts with '•', treat it as a list item
      if (line.startsWith('•')) {
        listItems.push(
          <li key={`li-${index}`}>
            {line.slice(1).trim()}
          </li>
        );
        inList = true;
      } else {
        // If we're currently in a list and encounter a non-list line, close the list
        if (inList) {
          elements.push(
            <ul key={`ul-${index}`} style={{ listStyleType: 'disc', paddingLeft: '20px' }}>
              {listItems}
            </ul>
          );
          listItems = [];
          inList = false;
        }
  
        // Handle empty line as a paragraph separator
        if (line === '') {
          elements.push(<br key={index} />);
        }
        // If it's a section title (for example, "Overview" or "Key Responsibilities")
        else if (line.match(/^[A-Za-z ]+$/) && (line.endsWith(':') || index === 0)) {
          elements.push(
            <h3 key={index}>
              {line}
            </h3>
          );
        }
        // Otherwise, treat it as a paragraph
        else {
          elements.push(
            <p key={index}>
              {line}
            </p>
          );
        }
      }
    });
  
    // If the last lines were list items, close the list
    if (inList) {
      elements.push(
        <ul key={`ul-final`}>
          {listItems}
        </ul>
      );
    }
  
    return elements;
  };
  

  return (
    <>
      {validurl?(
        <div className="flex flex-col justify center mt-24 px-20 md:px-2 mb-4 font-out ">
        {Object.keys(jobData).length === 0?(
          <div className="flex items-center justify-center py-72 sm:py-[280px]">
            <Loader/>
          </div>
        ):(
          <div className="animate__animated animate__zoomIn">
            <div
              className=" relative h-48 rounded-t-md sm:h-32"
              style={{
                backgroundImage: `url("${randomBanner}")`,
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                
              }}
            >
            <div className="absolute inset-0 bg-black bg-opacity-50 rounded-lg"></div>
              <img
                src={image}
                alt="descrip-img"
                className="w-16 h-16 top-40 left-10 relative rounded-md border-4 sm:top-[105px] sm:left-6 sm:w-12 sm:h-12 bg-white"
              />
            </div>
            {/* middle */}
            <div className="flex flex-col mt-14 gap-3">
              <div className="flex justify-between">
                <div className="flex flex-col">
                  <h1 className="mb-0 text-xl sm:text-[1rem]"> {window.innerWidth < 450 ? companyName?.slice(0, 25) : companyName?.slice(0, 65)}</h1>
                    <h1 className="mb-0 text-3xl sm:text-lg">{window.innerWidth < 450 ? jobTitle?.slice(0, 22) :jobTitle?.slice(0, 50)}</h1>
                  
                    <p className="text-gray-400 text-[1rem] mb-0 sm:text-[0.9rem]">
                      {jobType}
                    </p>
                </div>

                <div className="flex flex-col gap-2">
                  <button className="bg-blue-500 transition duration-400 hover:bg-blue-700  rounded-md text-white font-semibold text-md w-40 sm:w-28 h-10 sm:h-8  sm:text-[0.8rem] ">
                    <a href={jobLink} target="_blank" className="no-underline text-inherit"> Apply </a>
                  </button>
                  {isJobSaved && isLoggedIn?(
                    <button className='flex items-center justify-center gap-2  font-semibold bg-green-700 text-white rounded-md h-10 sm:h-8 w-40 sm:w-28 sm:text-[0.8rem]'>{Icons.saved} Saved</button>
                  ):(
                    <button className='flex items-center justify-center gap-2  font-semibold bg-slate-700 text-white rounded-md h-10 sm:h-8 w-40 sm:w-28 transition duration-400 hover:bg-black hover:text-white sm:text-[0.8rem]' onClick={addToLocalStorage}>{Icons.save} Save Job</button>
                  )}
                </div>
              </div>
              <div className="flex flex-wrap gap-2 items-center">
                {batch && batch.map((item, index) => (
                  <Tags key={index} batch={item} />
                ))}
                <span className="text-gray-400 text-[0.8rem]">{timeSince(datePosted)} ago</span>
              </div>
            </div>
            <hr />
            {/* bottom */}
            <div>
              <h1 className="text-[1.4rem] sm:text-lg">Overview</h1>
              <p className="text-[0.9rem] text-gray-400 font-semibold text-justify sm:text-[0.8rem]">
              {formatText(description)}
              {/* {window.innerWidth < 450 ? description?.slice(0, 1200) : description?.slice(0,5000)} */}
              </p>
            </div>
          </div>
        )}
        </div>
      ):(
        <Notfound/>
      )}
    </>
  );
};

export default JobDescription;
