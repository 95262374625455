import React, { useState, useEffect ,useCallback} from 'react';
import axios from 'axios';
import toast from 'react-hot-toast';
import Icons from '../../utils/icon';
import { firebaseImgUpload } from '../../utils/helper/firebase';
import { useCookies } from 'react-cookie';
import { debounce } from 'lodash';
import { useNavigate } from "react-router-dom";

const EditProfileModal = ({isModalOpen,setIsModalOpen,Email}) => {
  const navigate = useNavigate();
  const [cookies,setCookie, removeCookie] = useCookies(['_user']);
  const userData= cookies['_user'];
  const [usernameAvailability, setUsernameAvailability] = useState("");
  const [file, setFile] = useState(null);
  const [formData, setFormData] = useState({
    email: Email,
    name: "",
    username:"",
    picture:"",
  });

  const checkUsername = useCallback(
    debounce(async (username) => {
      try{
        const res= await axios.get(`${process.env.REACT_APP_BASE_URL}/api/user/checkUsernameAvailability?username=${username}`);
        console.log("checkUsername",res.data);
        setUsernameAvailability(res.data.message);
     }catch(e){
        if(e.response && e.response.status!==500 && e.response.data && e.response.data.status === false){
           setUsernameAvailability(e.response.data.message);
        }
     }
    }, 500),
    [] 
  );

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({...formData,[name]: value});
    if (name === 'username') {
      if (value === "" || value.trim()==="") {
        setUsernameAvailability("");
        checkUsername.cancel(); 
      } else {
        checkUsername(value);
      }
    }
  };

  useEffect(() => {
    if (isModalOpen) document.body.classList.add("overflow-hidden");
    else document.body.classList.remove("overflow-hidden");
  }, [isModalOpen]);

  const handleUpdate= async(e)=>{
    e.preventDefault();
    let updatedFormData = { ...formData };
    if(file){
      if(!isValidImage(file)){
        toast.error("Profile picture valid types: (PNG, JPEG)");
        return;
      }
      try{
          const url = await firebaseImgUpload(file);
          updatedFormData = { ...updatedFormData, picture: url };
          setFormData(updatedFormData);
      } catch(error){
          toast.error("Error uploading image");
      }
    }
    if(usernameAvailability==='Username is not available'){
      toast.error("Username Exists");
      return;
    } 
    if(formData.username==="" && formData.name==="" && file===null ) toast.error("Enter new details first");
    else{
      try{
        const res = await axios.put(`${process.env.REACT_APP_BASE_URL}/api/user/updateProfile`, updatedFormData);
        const responseData = res.data;
        if(responseData.status){
           removeCookie('_user');
           const expiryTimeInSeconds = 7 * 24 * 60 * 60; // 7 days in seconds
           setCookie('_user', responseData.user, { maxAge: expiryTimeInSeconds });
           toast.success(responseData.message);
           setIsModalOpen(false);
           navigate('/');
        }
      }catch(e){
         if(e.response && e.response.data && e.response.data.status === false){
           toast.error(e.response.data.message);
         }
      }
    } 
  }

  const isValidImage = (file) => {
    const acceptedImageTypes = ['image/jpeg', 'image/png'];
    return file && acceptedImageTypes.includes(file.type);
  }

  return (
        <>
          {isModalOpen && (
            <div className='font-out flex items-center justify-center absolute inset-0 bg-black z-20 bg-opacity-75 px-4'>
             <form className='rounded-md bg-white p-4 flex flex-col gap-3 sm:w-full' onSubmit={handleUpdate}>
                  <div className='flex justify-between items-center'>
                    <h1 className='text-lg mb-0'>Enter new profile details</h1>
                    <button className='text-xl cursor-pointer'  onClick={() => {setFormData({email: Email,name: "",username: "",picture: ""}); setUsernameAvailability(''); setIsModalOpen(false);}}>{Icons.cross}</button>
                  </div>
                  <div className='w-full'>
                  <input
                      className="p-2 rounded-lg outline-0 border text-md sm:text-sm w-full"
                      type="text"
                      name='username'
                      value={formData.username}
                      onChange={handleChange}
                      placeholder={userData.username}
                  />
                   <p className={`mb-0 text-sm ml-1 ${usernameAvailability==="Username is available" ? 'text-green-400' : 'text-red-400'}`}>{usernameAvailability}</p>
                  </div>
                 
                 
                   <input
                      className="p-2 rounded-lg outline-0 border text-md sm:text-sm"
                      type="text"
                      name='name'
                      value={formData.name}
                      onChange={handleChange}
                      placeholder={userData.name}
                  />
                  
                   <input
                      className="p-2 rounded-lg outline-0 border text-md sm:text-sm file:bg-blue-500 file:border-0 file:rounded-md file:px-4 file:py-1 file:text-white file:cursor-pointer file:hover:bg-blue-600"
                      type="file"
                      name='picture'
                      onChange={(e)=>{setFile(e.target.files[0])}}
                  />
                  <button type="submit" className="bg-blue-500 rounded-lg text-white py-2 sm:text-sm px-4 hover:bg-blue-600">Update Profile</button>
                </form>
            </div>
          )}
        </>
  );
}

export default EditProfileModal